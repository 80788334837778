<template>
  <div class="px-2">
    <div v-if="isAutonomous" class="mb-8">
      <h2 class="custom-title-secondary mb-1">
        Qual é a sua média de faturamento/renda mensal?
      </h2>
    </div>
    <div v-else>
      <h2 class="custom-title-secondary mb-1">
        Qual é a sua previsão de faturamento mensal?
      </h2>
      <p class="custom-normal-text">
        Esta informação é importante para que possamos te oferecer o modelo
        ideal de empresa.
      </p>
    </div>
    <v-row justify="center">
      <v-col cols="12">
        <v-select
          label="Faixa de faturamento mensal"
          solo
          :items="range"
          :loading="loading"
          item-text="name"
          item-value="id"
          ref="range"
          :change="onAnswer()"
          v-model="formUser.salesForecast.value"
          required
          :rules="[(v) => !!v || 'Escolha uma das opcões']"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    answer: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      range: [
        { name: 'Abaixo de R$ 1.900,00', id: 4 },
        { name: 'De R$ 1.901,00 até R$ 3.000,00', id: 5 },
        { name: 'De R$ 3.001,00 até R$ 6.750,00', id: 1 },
        { name: 'De R$ 6.751,00 a R$ 15.000,00', id: 2 },
        { name: 'Acima de R$ 15.000,00', id: 3 },
      ],
      loading: false,
      currentAnswer: this.answer,
    }
  },
  computed: {
    ...mapState({
      formUser: (state) => state.formDataUser.formUser,
    }),
    ...mapGetters({
      isAutonomous: 'formDataUser/isAutonomous',
    }),
  },
  methods: {
    onAnswer() {
      this.$emit('onAnswer', {
        idSalesForecast: this.formUser.salesForecast.value,
      })
    },
  },
}
</script>

<style module lang="scss">
@import '@design';
</style>
